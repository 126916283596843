import React from "react";
import { NavLink } from "react-bootstrap";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import Footer from "../Component/Footer";

const doctorReviewsWithImages = [
  {
    doctorName: "Dr. Ananya Kapoor, MD",
    specialty: "General Medicine",
    review:
      "Stetho has seamlessly integrated into my practice. The Medicine Management feature allows me to prescribe and manage medications efficiently, enhancing patient care.",
    image: "link_to_image_placeholder1",
  },
  {
    doctorName: "Dr. Rohit Sharma, Cardiologist",
    specialty: "Cardiology",
    review:
      "As a cardiologist, the Growth and Vital feature empowers me to closely monitor patients' vital signs. Stetho's intuitive design aligns perfectly with my practice needs.",
    image: "link_to_image_placeholder2",
  },
  {
    doctorName: "Dr. Nisha Patel, Family Physician",
    specialty: "Family Medicine",
    review:
      "The Profile and Family Management feature is a game-changer. It simplifies updating patient profiles and managing family health records, promoting holistic healthcare.",
    image: "link_to_image_placeholder3",
  },
  {
    doctorName: "Dr. Sameer Reddy, Oncologist",
    specialty: "Oncology",
    review:
      "Stetho's Test and Procedure List is invaluable in oncology. It streamlines the coordination of necessary tests, facilitating smoother patient care. Highly recommended!",
    image: "link_to_image_placeholder4",
  },
  {
    doctorName: "Dr. Sanjay Gupta, General Surgeon",
    specialty: "General Surgery",
    review:
      "Managing hospital details on Stetho is effortless. I can customize and update information promptly, ensuring my patients have accurate and real-time data. A reliable tool for any doctor.",
    image: "link_to_image_placeholder5",
  },
  {
    doctorName: "Dr. Priya Deshmukh, Pediatrician",
    specialty: "Pediatrics",
    review:
      "Stetho's Family Management feature has streamlined pediatric care. Managing children's health records and appointments has never been this efficient. A pediatrician's dream!",
    image: "link_to_image_placeholder6",
  },
  {
    doctorName: "Dr. Arvind Malik, Orthopedic Surgeon",
    specialty: "Orthopedics",
    review:
      "Stetho's Appointment History feature is a boon for orthopedics. Reviewing past consultations aids in better treatment plans. It's a reliable companion in my practice.",
    image: "link_to_image_placeholder7",
  },
  {
    doctorName: "Dr. Meenakshi Joshi, Gynecologist",
    specialty: "Gynecology",
    review:
      "Stetho's Prescription List is a fantastic tool for gynecology. Patient-specific prescription tracking ensures personalized and effective healthcare management.",
    image: "link_to_image_placeholder8",
  },
  {
    doctorName: "Dr. Karthik Iyer, Neurologist",
    specialty: "Neurology",
    review:
      "Navigating hospital schedules is effortless with Stetho's Hospital Detail and List feature. It ensures smooth coordination for neurology consultations and treatments.",
    image: "link_to_image_placeholder9",
  },
  {
    doctorName: "Dr. Anil Kapoor, Dermatologist",
    specialty: "Dermatology",
    review:
      "The Handout feature allows me to share skincare tips seamlessly. Stetho enhances patient education, making it an indispensable tool in dermatology.",
    image: "link_to_image_placeholder10",
  },
  {
    doctorName: "Dr. Ritu Sharma, Psychiatrist",
    specialty: "Psychiatry",
    review:
      "Stetho's user-friendly interface is a breath of fresh air. It simplifies mental health record management, ensuring a smooth and confidential patient experience.",
    image: "link_to_image_placeholder11",
  },
  {
    doctorName: "Dr. Sanjeev Reddy, Pulmonologist",
    specialty: "Pulmonology",
    review:
      "The Test and Procedure List is a game-changer for pulmonology. Coordinating respiratory tests has never been this efficient. Stetho truly understands a pulmonologist's needs.",
    image: "link_to_image_placeholder12",
  },
  {
    doctorName: "Dr. Ayesha Khan, Ophthalmologist",
    specialty: "Ophthalmology",
    review:
      "Stetho's Growth and Vital feature supports comprehensive eye care. Monitoring vital signs aids in diagnosing eye-related conditions promptly. A valuable tool for ophthalmology.",
    image: "link_to_image_placeholder13",
  },
  {
    doctorName: "Dr. Rajesh Malhotra, Urologist",
    specialty: "Urology",
    review:
      "Stetho's Medicine Management ensures seamless prescription updates. Managing medications for urological conditions is now hassle-free. A must-have for urologists.",
    image: "link_to_image_placeholder14",
  },
  {
    doctorName: "Dr. Anand Gupta, Cardiac Surgeon",
    specialty: "Cardiac Surgery",
    review:
      "As a cardiac surgeon, Stetho's comprehensive features enhance patient care. From appointment management to vital tracking, it aligns perfectly with cardiac healthcare needs.",
    image: "link_to_image_placeholder15",
  },
];

export default function Reviews() {
  return (
    <div>
      <section className="Faqsbanner hero-section ptb-100 background-img">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-9 col-lg-7">
              <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                <h1 className="text-white text-3xl mb-3">Doctor Reviews</h1>
                <div className="custom-breadcrumb flex justify-center">
                  <NavLink to="/" style={{ textDecoration: "underline" }}>
                    Home
                  </NavLink>
                  &nbsp;/&nbsp;
                  <strong>Reviews</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          <div className="grid gap-4 md:grid-cols-2 md:gap-8">
            {/* quote - start */}
            {doctorReviewsWithImages.map((value) => (
              <div className="flex flex-col items-center gap-4 rounded-lg bg-slate-200 px-8 py-6 md:gap-6">
                <div className="max-w-md text-center text-black lg:text-lg">
                  {value.review}
                </div>
                <div className="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
                  <div>
                    <Avatar
                      size={50}
                      icon={<UserOutlined />}
                      src={value.image}
                    />
                  </div>
                  <div>
                    <div className="text-center text-sm font-bold sm:text-left md:text-base text-black">
                      {value.doctorName}
                    </div>
                    <p className="text-left text-sm text-slate-600 sm:text-left md:text-sm">
                      {value.specialty}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            {/* quote - end */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
