import React from "react";

export default function Features() {
  return (
    <section className="bg-white py-16 sm:py-8 lg:py-12">
      <div className="container">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          {/* text - start */}
          <div className="mb-10 md:mb-16">
            <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">
            Features of Elevating Healthcare Management
            </h2>
            <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
              Stetho simplifies patient handling, appointments, and drug
              management for healthcare professionals with its intuitive
              interface.
            </p>
          </div>
          {/* text - end */}
          <div className="grid gap-12 sm:grid-cols-2 xl:grid-cols-3 xl:gap-16">
            <div className="flex flex-col items-center">
              <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-500 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-center text-lg font-semibold md:text-xl">
                Comprehensive Patient Management{" "}
              </h3>
              <p className="mb-2 text-center text-gray-500">
                Stetho simplifies patient handling, appointments, and drug
                management for healthcare professionals with its intuitive
                interface.
              </p>
            </div>
            {/* feature - start */}
            <div className="flex flex-col items-center">
              <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-500 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-center text-lg font-semibold md:text-xl">
                Multi-Hospital Schedule Control
              </h3>
              <p className="mb-2 text-center text-gray-500">
                Doctors gain seamless control over schedules across multiple
                hospitals, ensuring efficient and organized healthcare
                operations.
              </p>
            </div>
            {/* feature - end */}
            {/* feature - start */}
            <div className="flex flex-col items-center">
              <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-500 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-center text-lg font-semibold md:text-xl">
                Dashboard for Drug Managemen
              </h3>
              <p className="mb-2 text-center text-gray-500">
                Stetho's dashboard empowers healthcare providers to add, manage
                drugs, and calculate amounts effortlessly, enhancing workflow
                efficiency.
              </p>
            </div>
            {/* feature - end */}
            {/* feature - start */}
            <div className="flex flex-col items-center">
              <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-500 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-center text-lg font-semibold md:text-xl">
                Referral System
              </h3>
              <p className="mb-2 text-center text-gray-500">
                Facilitates smooth referrals between doctors and clinics,
                fostering collaborative healthcare practices within the Stetho
                ecosystem
              </p>
            </div>
            {/* feature - end */}
            {/* feature - start */}
            <div className="flex flex-col items-center">
              <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-500 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-center text-lg font-semibold md:text-xl">
                User-Friendly Appointment Handling
              </h3>
              <p className="mb-2 text-center text-gray-500">
                tetho streamlines appointment scheduling, providing an
                easy-to-use platform for both medical practitioners and
                patients.
              </p>
            </div>
            {/* feature - end */}
            {/* feature - start */}
            <div className="flex flex-col items-center">
              <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-500 text-white shadow-lg md:h-14 md:w-14 md:rounded-xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-center text-lg font-semibold md:text-xl">
                Financial Insights
              </h3>
              <p className="mb-2 text-center text-gray-500">
                Stetho offers financial transparency by providing insights into
                amounts calculated, contributing to informed decision-making in
                healthcare management.{" "}
              </p>
            </div>
            {/* feature - end */}
            {/* feature - start */}

            {/* feature - end */}
          </div>
        </div>
      </div>
    </section>
  );
}
