import React, { useRef } from "react";
import Telemedicine01 from "../../Images/Telemedicine_01.svg";
import heroanimation01 from "../../Images/hero-animation-01.svg";
import Telemedicine03 from "../../Images/Telemedicine_03.svg";
import heroanimation03 from "../../Images/hero-animation-03.svg";
import herobgshape2 from "../../Images/hero-bg-shape-2.svg";
import "../../CSS/Home.css";
import { NavLink } from "react-router-dom";

export default function Hero() {
  const scrollTo = () => {
    const targetDiv = document.getElementById("startDiv");

    // Use the scrollIntoView method to scroll to the target div
    targetDiv.scrollIntoView({
      behavior: "smooth", // You can adjust the scroll behavior
      block: "start", // You can adjust the scroll block (start, center, end)
    });
  };
  return (
    <div className="Home-Section-1">
      <section className="hero-section hero-section-3 ptb-100">
        {/*animated circle shape start*/}
        <div className="circles">
          <div className="point animated-point-1" />
          <div className="point animated-point-2" />
          <div className="point animated-point-3" />
          <div className="point animated-point-4" />
          <div className="point animated-point-5" />
          <div className="point animated-point-6" />
        </div>
        {/*animated circle shape end*/}
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6 order-md-1 order-2">
              <div className="hero-content-left ptb-100">
                <h1 className="fw-bold">
                  Stetho: Feel the Pulse of Connected Healthcare
                </h1>
                <p className="lead text-base text-gray-500">
                  Immerse yourself in Stetho, where appointments sync
                  seamlessly, medical records flow effortlessly, and
                  prescriptions pulse directly to your fingertips. Join the
                  heartbeat of connected health – Stetho, where you feel the
                  rhythm of care.
                </p>
                <div className="buttonGroup flex items-center justify-start mt-3">
                  <button onClick={scrollTo} className="btn solid-btn">
                    Start Now
                  </button>
                  <NavLink to="/contact-us">
                    <button className="contact-us mx-4">Contact Now</button>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-5 order-md-2 order-1">
              <div className="hero-animation-img">
                <img
                  className="img-fluid d-block m-auto animation-one"
                  src={Telemedicine01}
                  width={150}
                  alt="animation image"
                />
                <img
                  className="img-fluid d-none d-lg-block animation-two"
                  src={heroanimation01}
                  alt="animation image"
                  width={120}
                />
                <img
                  className="img-fluid d-none d-lg-block animation-three"
                  src={Telemedicine03}
                  alt="animation image"
                  width={120}
                />
                <img
                  className="img-fluid d-none d-lg-block animation-four"
                  src={heroanimation03}
                  alt="animation image"
                  width={200}
                />
              </div>
            </div>
          </div>
        </div>
        {/*shape image start*/}
        <img src={herobgshape2} className="shape-image" alt="shape image" />
        {/*shape image end*/}
      </section>
    </div>
  );
}
