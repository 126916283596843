import React from "react";
import Footer from "../Component/Footer";

export default function PrivacyPolicy() {
  return (
    <>
      <div className="container">
        <h2 className="text-bold text-black text-3xl mb-3">Privacy Policy</h2>
        <p>
          This app is developed by STETHO as a commercial app. The app is
          provided to you as is and the following policies apply to the
          collection, use, and disclosure of personal information for users who
          decide to use our service. By choosing to use our service, you agree
          to the policies stated herein regarding the collection and use of
          personal information. We collect personal information to provide and
          improve our services, and we will not share or use your information
          except as described in this Privacy Policy. The terms used in this
          Privacy Policy are the same as those used in our Terms and Conditions
          accessible at Patient unless otherwise stated herein.
        </p>
        <h4 className="text-bold text-slate-700 text-2xl mt-3 mb-3">
          Information Collection and Use
        </h4>
        <p>
          To provide a better experience while using our service, we may require
          you to provide certain personally identifiable information, including
          but not limited to personal information, contact list, videos, images,
          and device details such as Android Device ID. The information we
          collect will be used and retained by us as described in this Privacy
          Policy. Our app may use third-party services that collect information
          to identify you. The privacy policies of third-party service providers
          used by the app are accessible through the links provided in the app.
        </p>
        <h4 className="text-bold text-slate-700 text-2xl mt-3 mb-3">
          Log Data
        </h4>
        <p>
          Whenever you use our service and in case of an error in the app, we
          collect data and information (through third-party products) on your
          phone called Log Data. This Log Data may include information such as
          your device's Internet Protocol (â€œIPâ€) address, device name,
          operating system version, the configuration of the app when utilizing
          our service, the time and date of your use of the service, and other
          statistics.
        </p>
        <h4 className="text-bold text-slate-700 text-2xl mt-3 mb-3">Cookies</h4>
        <p>
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites you visit and are stored on your device's internal
          memory. Our app does not use cookies explicitly, but third-party code
          and libraries used by the app may use cookies to collect information
          and improve their services. You have the option to accept or refuse
          these cookies and know when a cookie is being sent to your device. If
          you refuse our cookies, you may not be able to use some portions of
          this service.
        </p>
        <h4 className="text-bold text-slate-700 text-2xl mt-3 mb-3">
          Service Providers
        </h4>
        <p>
          We may employ third-party companies and individuals to perform tasks
          on our behalf, including facilitating our service, providing the
          service on our behalf, performing service-related services, or
          assisting us in analyzing how our service is used. These third parties
          have access to your personal information and are obligated not to
          disclose or use the information for any other purpose.
        </p>
        <h4 className="text-bold text-slate-700 text-2xl mt-3 mb-3">
          Security
        </h4>
        <p>
          We use commercially acceptable means to protect your personal
          information, but we cannot guarantee its absolute security since no
          method of transmission over the internet or method of electronic
          storage is 100% secure and reliable.
        </p>
        <h4 className="text-bold text-slate-700 text-2xl mt-3 mb-3">
          Links to Other Sites
        </h4>
        <p>
          This service may contain links to other sites. Please note that these
          external sites are not operated by us and we assume no responsibility
          for their content, privacy policies, or practices. We advise you to
          review the privacy policy of these websites before using them.
        </p>
        <h4 className="text-bold text-slate-700 text-2xl mt-3 mb-3">
          Childrenâ€™s Privacy
        </h4>
        <p>
          Our service is not intended for children under the age of 13. We do
          not knowingly collect personally identifiable information from
          children under 13 years of age. If we discover that a child under 13
          has provided us with personal information, we immediately delete it
          from our servers. If you are a parent or guardian and are aware that
          your child has provided us with personal information, please contact
          us so that we can take the necessary actions
        </p>
        <p></p>
        <h4 className="text-bold text-slate-700 text-2xl mt-3 mb-3">
          Changes to This Privacy Policy
        </h4>
        <p className="mb-4">
          We reserve the right to update our Privacy Policy from time to time.
          Any changes made will be effective upon posting the new Privacy Policy
          on this page. We encourage you to review this page periodically for
          any changes. If you have any questions about our Privacy Policy,
          please contact us.
        </p>
      </div>
      <Footer />
    </>
  );
}
