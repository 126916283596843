import React from "react";
import appointment from "../../Images/DoctorApp/1.png";
import hospital from "../../Images/DoctorApp/3.png";
import patient from "../../Images/DoctorApp/2.png";
import medicine from "../../Images/DoctorApp/4.png";
import { IoCheckmarkCircle } from "react-icons/io5";

export default function AboutDoctorApp() {
  return (
    <div>
      <section className="doctorFeatures bg-gray-100 py-5">
        <div className="container">
          <div
            className="section-heading text-center"
            style={{ maxWidth: "900px", margin: "auto" }}
          >
            <span className="text-uppercase color-secondary sub-title">
              Doctor App Features
            </span>
            <h2>FEATURES OF DOCTOR APPLICATION.</h2>
            <p className="w-full">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi,
              nihil, laboriosam neque adipisci eum repellendus hic odio iure
              iste vitae ipsum sequi sunt iusto voluptatibus deserunt rerum
              voluptatem expedita est!
            </p>
          </div>
          <div className="row p-0 m-0">
            <div className="col-12 pt-lg-5">
              <div className="row align-items-end">
                <div className="col-lg-6 order-lg-2 text-center">
                  <img
                    className="img-fluid"
                    src={appointment}
                    alt="working process"
                    width={400}
                  />
                </div>
                <div className="col-lg-6 border-start border-bottom pb-lg-5 mt-5 mb-5 ">
                  <div className="process-item ms-5 ms-sm-8 ms-lg-5">
                    <span className="process-item-number">01</span>
                    <h3>Appointments Management</h3>
                    <p>
                      Stetho's appointment system excels with versatile
                      scheduling, offline capability, and a user-friendly
                      interface for seamless healthcare management.
                    </p>
                    <ul>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Doctors manage multiple schedules, optimizing time
                          across hospitals.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Hospitals function seamlessly offline for
                          uninterrupted service.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Efficient regeneration of slots to minimize patient
                          wait times.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Quick and hassle-free patient registration process.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Instant visibility into available slots for patient
                          convenience.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Intuitive interface for rapid appointment booking for
                          new patients.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Seamless integration of patient history for informed
                          consultations.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Automatic alerts for confirmed appointments, reducing
                          no-shows.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Intuitive design for efficient appointment management
                          and patient navigation.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-lg-6 border-end border-bottom text-center py-lg-5">
                  <img
                    className="img-fluid"
                    src={hospital}
                    alt="working process"
                    width={400}
                  />
                </div>
                <div className="col-lg-6 pb-lg-5 mt-5 mb-5">
                  <div className="process-item ms-5 ms-sm-8 ms-lg-6">
                    <span className="process-item-number">02</span>
                    <h3>Hospital's Management</h3>
                    <p>
                      Stetho's hospital management empowers doctors with
                      unlimited uploads, customization freedom, and seamless
                      control, ensuring efficient consultation setup anywhere,
                      anytime.
                    </p>
                    <ul>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Doctors can upload an unlimited number of hospitals
                          for consultation.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Doctors have the flexibility to customize hospitals as
                          needed.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Effortlessly set up hospitals for consultation
                          anywhere the doctor goes.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Doctors can dynamically control and modify hospital
                          settings as required.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Intuitive design for easy customization and management
                          of hospitals.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Access hospitals and modify settings whenever and
                          wherever needed.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Streamlined process for doctors to manage multiple
                          hospital consultations.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Stetho imposes no limits on the number of hospitals a
                          doctor can manage.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Instant updates for doctors on changes made to
                          hospital configurations.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-lg-6 order-lg-2 text-center align-self-center">
                  <img
                    className="img-fluid"
                    src={medicine}
                    alt="working process"
                    width={400}
                  />
                </div>
                <div className="col-lg-6 border-start border-bottom pb-lg-5 mt-5 mb-5">
                  <div className="process-item ms-5 ms-sm-8 ms-lg-6">
                    <span className="process-item-number">03</span>
                    <h3>Medicine's Management</h3>
                    <p>
                      Stetho's medicine management empowers doctors with easy
                      uploads, drag-and-drop functionality, quick search, and
                      detailed customization, ensuring efficient and
                      personalized healthcare for patients.
                    </p>
                    <ul>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Doctors can easily upload their own medicines for
                          personalized use.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          User-friendly drag-and-drop feature simplifies
                          medicine management.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Instantly locate medicines with a convenient search
                          function.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Doctors can effortlessly update details of previously
                          added medicines.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Maintain records of medicine brands and prices for
                          accurate prescribing.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Customize taking times for medicines based on patient
                          needs.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Set and adjust the frequency of medicine intake as
                          required.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          No restrictions on the number of times a medicine can
                          be used.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Streamline the process with time-efficient medicine
                          management.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row pb-1">
                <div className="col-lg-6 border-end text-center py-8">
                  <img
                    className="img-fluid"
                    src={patient}
                    alt="working process"
                    width={400}
                  />
                </div>
                <div className="col-lg-6 align-self-center pb-lg-5 mt-5 mb-5">
                  <div className="process-item process-item-last ms-5 ms-sm-8 ms-lg-6">
                    <strong className="process-item-number">04</strong>
                    <h3>Patient's Management</h3>
                    <p>
                      Stetho's patient management offers doctors effortless
                      addition, bookmark customization, complete history
                      control, and bulk uploads, ensuring efficient and
                      personalized healthcare services.
                    </p>
                    <ul>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Doctors can easily add and update patient information.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Assign specific bookmarks like favorites or irritating
                          to patients.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Doctors have comprehensive control over patient
                          consultation history.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Easily manage and access patient reports for informed
                          decision-making.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Streamline the process by uploading multiple patients
                          using Excel sheets.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Doctors can set preferences for each patient based on
                          their history.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Ensure efficiency with organized and accessible
                          patient data.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Tailor consultations based on patient history and
                          preferences.
                        </span>
                      </li>
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          Doctors can upload patient data in bulk effortlessly
                          through Excel sheets.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
