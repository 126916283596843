import React from "react";
import PatientHero from "../Component/PatientApp/patientHero";
import AppLink from "../Component/PatientApp/AppLink";
import AboutApp from "../Component/PatientApp/AboutApp";
import Footer from "../Component/Footer";

export default function PatinetApp() {
  return (
    <div>
      <PatientHero />
      <AppLink />
      <AboutApp />
      <Footer />
    </div>
  );
}
