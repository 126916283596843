import { Divider } from "antd";
import React from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import appointment from "../../Images/PatientApp/Appointment.png";
import appointmentHistory from "../../Images/PatientApp/history.png";
import hospital from "../../Images/PatientApp/hospital.png";
import prescription from "../../Images/PatientApp/priscription.png";
import patient from "../../Images/PatientApp/patient.png";
import test from "../../Images/PatientApp/test.png";
import vital from "../../Images/PatientApp/vital.png";
import handout from "../../Images/PatientApp/handout.png";

const features = [
  {
    title: "Book Appointment",
    image: appointment,
    description:
      "Patients can choose hospitals and doctors, view real-time slot availability, specify booking reasons, and receive immediate confirmation via SMS.",
    points: [
      {
        key: "Choose hospitals and doctors",
        value: "Select preferred healthcare providers.",
      },
      {
        key: "View real-time slot availability",
        value: "Check the availability of appointment slots in real-time.",
      },
      {
        key: "Specify booking reasons",
        value: "Provide details about the purpose of the appointment.",
      },
      {
        key: "Receive immediate confirmation via SMS",
        value:
          "Get instant confirmation for the booked appointment through SMS.",
      },
      {
        key: "User-Friendly Interface",
        value: "Navigate through the booking process with an intuitive design.",
      },
    ],
  },
  {
    title: "Appointment History",
    image: appointmentHistory,
    description:
      "Access a comprehensive list of recent and past appointments, cancel appointments within the app, view detailed appointment information, and track hospital locations.",
    points: [
      {
        key: "Access comprehensive list of appointments",
        value: "Review a detailed list of past and upcoming appointments.",
      },
      {
        key: "Cancel appointments within the app",
        value: "Easily cancel appointments when needed.",
      },
      {
        key: "View detailed appointment information",
        value: "Access information such as date, time, and booked doctor.",
      },
      {
        key: "Track hospital locations",
        value:
          "Get directions and track the location of the hospital for upcoming visits.",
      },
      {
        key: "Seamless User Interface",
        value:
          "Navigate through the Appointment History with an intuitive design.",
      },
    ],
  },
  {
    title: "Prescription List",
    image: prescription,
    description:
      "Organized tracking of prescriptions based on visit date, detailed prescription views, zoom capability for closer examination, and easy sharing with other healthcare professionals or family members.",
    points: [
      {
        key: "Organized tracking based on visit date",
        value:
          "Maintain a structured list of prescriptions based on visit dates.",
      },
      {
        key: "Detailed prescription views",
        value:
          "View comprehensive details of each prescription, including medication information.",
      },
      {
        key: "Zoom capability for closer examination",
        value: "Zoom in on prescriptions for a closer and clearer view.",
      },
      {
        key: "Easy sharing with other healthcare professionals or family members",
        value: "Share prescriptions effortlessly for collaboration.",
      },
      {
        key: "User-Friendly Interface",
        value:
          "Navigate through the Prescription List with an intuitive design.",
      },
    ],
  },
  {
    title: "Hospital Detail and List",
    image: hospital,
    description:
      "Explore hospitals, check doctor schedules, navigate to hospitals, access contact information, discover amenities and services, all within a user-friendly interface.",
    points: [
      {
        key: "Hospital Listing",
        value:
          "Explore a comprehensive list of hospitals where doctors are available for appointments.",
      },
      {
        key: "Doctor Schedule",
        value:
          "Check the schedule for each hospital, ensuring patients know when their preferred doctors are available.",
      },
      {
        key: "Navigation Assistance",
        value:
          "Seamlessly redirect to hospital maps for convenient navigation to the selected healthcare facility.",
      },
      {
        key: "Contact Information",
        value:
          "Access hospital contact numbers and addresses for better communication and easy reach.",
      },
      {
        key: "Amenities and Services",
        value:
          "Discover additional amenities and services offered by each hospital for informed decision-making.",
      },
      {
        key: "User-Friendly Interface",
        value:
          "Navigate through hospital details with an intuitive design for a seamless user experience.",
      },
    ],
  },
  {
    title: "Profile and Family Management",
    image: patient,
    description:
      "Manage personal profiles, add and update family members, and book appointments for them, all within an intuitive user interface.",
    points: [
      {
        key: "Personal Profile Updates",
        value:
          "Patients can easily update and maintain their personal profiles, ensuring accuracy.",
      },
      {
        key: "Family Member Inclusion",
        value:
          "Add and categorize family members based on relationships for streamlined health tracking.",
      },
      {
        key: "Dynamic Family Member Updates",
        value:
          "Effortlessly update family members' details to keep health records current and comprehensive.",
      },
      {
        key: "Appointment Booking for Family",
        value:
          "Conveniently schedule appointments for family members within the app.",
      },
      {
        key: "User-Friendly Interface",
        value:
          "Navigate through the Profile and Family Management feature with an intuitive design for a seamless experience.",
      },
    ],
  },
  {
    title: "Handout",
    image: handout,
    description:
      "Access expert insights on healthy living and fitness from doctors, share valuable handouts with others, all within a user-friendly interface.",
    points: [
      {
        key: "Doctor's Health Insights",
        value:
          "Access handouts where doctors share valuable thoughts on healthy living and fitness.",
      },
      {
        key: "Guidance for Better Health",
        value:
          "Explore expert advice and guidance to enhance overall well-being and lifestyle choices.",
      },
      {
        key: "Patient Sharing",
        value:
          "Share handouts with others, extending the reach of valuable health information within the community.",
      },
      {
        key: "User-Friendly Interface",
        value:
          "Navigate through handouts with an intuitive design, ensuring a seamless reading experience.",
      },
    ],
  },
  {
    title: "Growth and Vital",
    image: vital,
    description:
      "Empower users to actively monitor and manage their health metrics. Patients can update vital records, track growth, and maintain a history of weight, height, BMI, blood pressure, oxygen levels, respiratory rate, temperature, and pulse.",
    points: [
      {
        key: "Self-Update Health Records",
        value:
          "Patients can independently update their health records, including vital metrics and growth parameters.",
      },
      {
        key: "History Management",
        value:
          "Maintain a comprehensive history of weight, height, BMI, blood pressure, oxygen levels, respiratory rate, temperature, and pulse.",
      },
      {
        key: "Dynamic Growth Tracking",
        value:
          "Observe how vital metrics have changed over time, aiding in personalized health assessment.",
      },
      {
        key: "Flexible Editing",
        value:
          "Modify and remove previous entries for accurate and up-to-date health history.",
      },
      {
        key: "User-Friendly Interface",
        value:
          "Navigate through Growth and Vital records with an intuitive design for a seamless user experience.",
      },
    ],
  },
  {
    title: "Test and Procedure List",
    image: test,
    description:
      "Streamline the management of medical tests and procedures. Access a detailed list of prescribed tests, view scheduled dates, obtain lab contact information, and directly communicate with the lab for seamless coordination.",
    points: [
      {
        key: "Comprehensive Test Overview",
        value:
          "Access a detailed list of prescribed tests and procedures recommended by the doctor.",
      },
      {
        key: "Scheduled Test Dates",
        value:
          "View scheduled dates for each test or procedure, ensuring timely completion.",
      },
      {
        key: "Lab Contact Information",
        value:
          "Obtain contact details of the specified lab, facilitating easy communication for queries or appointments.",
      },
      {
        key: "Direct Lab Communication",
        value:
          "Contact the lab directly through the app using the provided contact information for seamless coordination.",
      },
      {
        key: "User-Friendly Interface",
        value:
          "Navigate through the Test and Procedure List with an intuitive design, ensuring a hassle-free experience.",
      },
    ],
  },
];

export default function AboutApp() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container">
        <div
          className="section-heading text-center"
          style={{ maxWidth: "900px", margin: "auto" }}
        >
          <span className="text-uppercase color-secondary sub-title">
            Patient App Features
          </span>
          <h2>FEATURES OF PATIENT APPLICATION.</h2>
          <p className="w-full">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi,
            nihil, laboriosam neque adipisci eum repellendus hic odio iure iste
            vitae ipsum sequi sunt iusto voluptatibus deserunt rerum voluptatem
            expedita est!
          </p>
        </div>
        {features.map((value, index) => (
          <>
            <div className={`row ${index === 0 && `mt-5`}`}>
              <div
                className={`col-md-6 ${
                  (index + 1) % 2 === 0 ? "order-2" : "order-2 order-lg-1"
                } `}
              >
                <div className="featureContent">
                  <h3>{value.title}</h3>
                  <p>{value.description}</p>
                  <ul>
                    {value.points.map((valuePoint) => (
                      <li>
                        <IoCheckmarkCircle />
                        <span>
                          <strong>{valuePoint.key}</strong>
                          <p>{valuePoint.value}</p>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                className={`col-md-6 ${
                  (index + 1) % 2 === 0 ? "order-1" : "order-1 order-lg-2"
                } `}
              >
                <img className="patientAppImage" src={value.image} />
              </div>
            </div>
            {index === features.length - 1 ? null : <Divider />}
          </>
        ))}
      </div>
    </section>
  );
}
