import React from "react";
import "../CSS/Faqs.css";
import Footer from "../Component/Footer";
import { NavLink } from "react-router-dom";
import { Collapse } from "antd";
import { IoAddOutline } from "react-icons/io5";
import { FaMinus } from "react-icons/fa6";

const faqs = [
  {
    question: "How do I book an appointment using the Stetho app?",
    answer:
      "To book an appointment, select your preferred hospital and doctor, choose an available slot, and provide the reason for the appointment. Confirm your booking, and you'll receive an SMS notification.",
  },
  {
    question: "Can I view my past appointments and their details in the app?",
    answer:
      "Yes, the 'Appointment History' feature allows you to access a detailed list of both past and upcoming appointments, including date, time, and the booked doctor's information.",
  },
  {
    question: "What information is available in the Prescription List feature?",
    answer:
      "The Prescription List feature organizes prescriptions based on visit dates. You can view detailed medication information, zoom in for a closer look, and easily share them with healthcare professionals or family members.",
  },
  {
    question: "How can I update my personal profile and add family members?",
    answer:
      "Navigate to the 'Profile and Family Management' feature. Here, you can easily update your personal details and add or update family members, categorizing them based on relationships.",
  },
  {
    question: "Is it possible to cancel an appointment within the Stetho app?",
    answer:
      "Yes, you can cancel appointments conveniently within the app. Access the 'Appointment History' section, select the appointment you wish to cancel, and follow the cancellation process.",
  },
  {
    question:
      "What hospitals and doctors are available on the Stetho platform?",
    answer:
      "Stetho provides a comprehensive list of hospitals and doctors. Explore the 'Hospital Detail and List' feature to check schedules, contact information, and additional amenities offered by each hospital.",
  },
  {
    question:
      "How does the Handout feature work, and what kind of insights does it provide?",
    answer:
      "The Handout feature allows you to access expert insights on healthy living and fitness shared by doctors. You can explore guidance for better health and easily share these handouts with others.",
  },
  {
    question: "Can I track my health metrics and growth using the app?",
    answer:
      "Yes, the 'Growth and Vital' feature empowers you to actively monitor and manage health metrics such as weight, height, BMI, blood pressure, oxygen levels, respiratory rate, temperature, and pulse.",
  },
  {
    question:
      "What types of tests and procedures are included in the Test and Procedure List?",
    answer:
      "The Test and Procedure List feature provides a detailed overview of prescribed tests. You can view scheduled dates, obtain lab contact information, and directly communicate with the lab for coordination.",
  },
  {
    question: "How do I contact a lab specified by my doctor for tests?",
    answer:
      "In the Test and Procedure List, find the lab contact information provided by your doctor. Use this information to contact the lab directly through the app for any queries or appointment-related communication.",
  },
  {
    question:
      "Is there a feature to share health information with family members or healthcare professionals?",
    answer:
      "Yes, you can share prescriptions, handouts, and other health information with family members or healthcare professionals directly through the app.",
  },
  {
    question:
      "Can I receive SMS notifications for appointment confirmations and reminders?",
    answer:
      "Absolutely! Stetho sends SMS notifications to confirm your booked appointments and provides timely reminders for upcoming ones.",
  },
  {
    question:
      "How does the app handle offline functionality, especially for hospitals?",
    answer:
      "Hospitals can operate offline, ensuring continuous service even in areas with limited connectivity. Appointments can be managed seamlessly, and doctors can update their schedules without internet access.",
  },
  {
    question: "Is there a way to navigate to a hospital using the Stetho app?",
    answer:
      "Yes, the app provides navigation assistance. You can seamlessly redirect to hospital maps for convenient navigation to the selected healthcare facility.",
  },
  {
    question:
      "Can I customize my hospital details as a consulting doctor on the platform?",
    answer:
      "Yes, doctors can upload and customize their hospital details, ensuring accurate and up-to-date information for patients.",
  },
  {
    question:
      "What kind of information is available in the Growth and Vital feature?",
    answer:
      "The Growth and Vital feature allows you to monitor and maintain a history of weight, height, BMI, blood pressure, oxygen levels, respiratory rate, temperature, and pulse. You can dynamically track changes over time.",
  },
  {
    question:
      "How does the app ensure the security and privacy of my health records?",
    answer:
      "Stetho prioritizes the security and privacy of your health records. Stringent measures are in place to safeguard sensitive information, adhering to industry-standard protocols.",
  },
  {
    question:
      "Can I add and update my own medicines in the Medicine Management feature?",
    answer:
      "Yes, doctors can upload their own medicines, making it convenient for them to manage and prescribe medications. The feature supports drag-and-drop functionality and easy updating of medicine details.",
  },
  {
    question:
      "Is there a search functionality in the app to find specific information quickly?",
    answer:
      "Absolutely! The app features a search functionality, enabling users to find specific information quickly and efficiently.",
  },
  {
    question:
      "How can I share handouts provided by doctors with other individuals?",
    answer:
      "Sharing handouts is simple. Navigate to the Handout feature, select the desired handout, and use the sharing options within the app to extend valuable health information to others.",
  },
];

const Faqs = () => {
  return (
    <>
      <section className="Faqsbanner hero-section ptb-100 background-img">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-9 col-lg-7">
              <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                <h1 className="text-white text-3xl mb-3">FAQ Page</h1>
                <div className="custom-breadcrumb">
                  <NavLink to="/" style={{ textDecoration: "underline" }}>
                    Home
                  </NavLink>
                  &nbsp; /&nbsp;
                  <strong>FAQ</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ------------------------ Section 2 ------------------- */}

      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-9">
              <div className="section-heading mb-5">
                <h2>Frequently Asked Questions</h2>
                <p className="lead">
                  Quickly morph client-centric results through performance based
                  applications. Proactively facilitate professional human
                  capital for cutting-edge.
                </p>
              </div>
            </div>
          </div>
          {/*pricing faq start*/}
          <div className="row">
            {faqs.map((value, index) => (
              <div className="col-lg-6 mb-3" key={index}>
                <Collapse
                  style={{
                    border: "none",
                    background: "#fff",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 5px #d1d1d1",
                    padding: 5
                  }}
                  bordered={false}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <FaMinus style={{ color: "#632CAE", fontSize: 20 }} />
                    ) : (
                      <IoAddOutline
                        style={{ color: "#632CAE", fontSize: 20 }}
                      />
                    )
                  }
                  expandIconPosition="end"
                  items={[
                    {
                      key: index + 1,
                      label: value.question,
                      children: <p>{value.answer}</p>,
                    },
                  ]}
                />
              </div>
            ))}
          </div>
          {/*pricing faq end*/}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Faqs;
