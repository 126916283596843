import React from "react";

import "../CSS/Home.css";
import Footer from "../Component/Footer";
import Hero from "../Component/Home/Hero";
import DoctorApp from "../Component/Home/DoctorApp";
import PatientApp from "../Component/Home/PatientApp";
import AboutApp from "../Component/Home/AboutApp";
import WhyChooseUs from "../Component/Home/WhyChooseUs";
import ReceptionApp from "../Component/Home/ReceptionApp";
import Testimonial from "../Component/Home/Testimonial";

const Home = () => {
  return (
    <>
      <Hero />
      <DoctorApp />
      <PatientApp />
      <AboutApp />
      <WhyChooseUs />
      <ReceptionApp />
      <Testimonial />
      <Footer />
    </>
  );
};

export default Home;
