import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./Component/NavBar";
import Home from "./Pages/Home";
import Faqs from "./Pages/Faqs";
import AppLink from "./Pages/AppLink";
import CommingSoon from "./Pages/CommingSoon";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import DoctorApp from "./Pages/DoctorApp";
import ReceptionApp from "./Pages/ReceptionApp";
import PatinetApp from "./Pages/PatientApp";
import Reviews from "./Pages/Reviews";
import ContactUs from "./Pages/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ScrollToTop from "./Component/ScrollTo";
import BookAppointment from "./Pages/BookAppointment";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          {/* <Route path="/" element={<CommingSoon />} /> */}
          <Route path="/app/:id" element={<AppLink />} />
          <Route
            path="/"
            element={
              <>
                <NavBar />
                <Home />
              </>
            }
          />
          <Route
            path="/faqs"
            element={
              <>
                <NavBar />
                <Faqs />
              </>
            }
          />
          <Route
            path="/doctor-app"
            element={
              <>
                <NavBar />
                <DoctorApp />
              </>
            }
          />
          <Route
            path="/reception-app"
            element={
              <>
                <NavBar />
                <ReceptionApp />
              </>
            }
          />
          <Route
            path="/patient-app"
            element={
              <>
                <NavBar />
                <PatinetApp />
              </>
            }
          />
          <Route
            path="/doctor-reviews"
            element={
              <>
                <NavBar />
                <Reviews />
              </>
            }
          />
          <Route
            path="/contact-us"
            element={
              <>
                <NavBar />
                <ContactUs />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <NavBar />
                <PrivacyPolicy />
              </>
            }
          />
          <Route path="/book-appointment/:token" element={<BookAppointment />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
