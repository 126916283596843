import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "../Component/Footer";
import reception from "../Images/Reception.png";

export default function ReceptionApp() {
  return (
    <div>
      <div className="container">
        <div class="bg-white pb-6 sm:pb-8 lg:pb-12">
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <section className="max-h-96 relative flex flex-1 shrink-0 items-center justify-center overflow-hidden rounded-lg bg-gray-100 py-16 shadow-lg md:py-20 xl:py-48">
              {/* image - start */}
              <img
                src="https://www.shutterstock.com/image-photo/top-view-office-desk-table-260nw-1680405310.jpg"
                loading="lazy"
                alt="Photo by Fakurian Design"
                className="absolute inset-0 h-full w-full object-cover object-center"
              />
              {/* image - end */}
              {/* overlay - start */}
              <div className="absolute inset-0 bg-indigo-300 mix-blend-multiply" />
              {/* overlay - end */}
              {/* text start */}
              <div className="relative flex flex-col items-center p-4 sm:max-w-xl">
                <p className="mb-4 text-center text-lg text-indigo-200 sm:text-xl md:mb-8">
                  Very proud to introduce
                </p>
                <h1 className="mb-8 text-center text-4xl font-bold text-white sm:text-5xl md:mb-12 md:text-6xl">
                  Reception Application
                </h1>
                <div className="flex w-full flex-col gap-2.5 sm:flex-row sm:justify-center">
                  {/* <NavLink
                    to="#"
                    className="inline-block rounded-lg bg-red-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-red-600 focus-visible:ring active:bg-red-700 md:text-base"
                  >
                    Start now
                  </NavLink> */}
                  <NavLink
                    to="/contact-us"
                    className="inline-block rounded-lg bg-gray-200 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-300 focus-visible:ring active:text-gray-700 md:text-base"
                  >
                    Enquire Now
                  </NavLink>
                </div>
              </div>
              {/* text end */}
            </section>
          </div>
        </div>
      </div>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <div
            className="section-heading text-center"
            style={{ maxWidth: "900px", margin: "auto" }}
          >
            <span className="text-uppercase color-secondary sub-title">
              Reception App Features
            </span>
            <h2>FEATURES OF DOCTOR APPLICATION.</h2>
            <p className="w-full">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi,
              nihil, laboriosam neque adipisci eum repellendus hic odio iure
              iste vitae ipsum sequi sunt iusto voluptatibus deserunt rerum
              voluptatem expedita est!
            </p>
          </div>
          <div className="flex flex-wrap w-full mt-5">
            <img
              className="flex lg:hidden xl:w-3/5 lg:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12"
              src={reception}
              alt="step"
            />
            <div className="xl:w-2/5 lg:w-1/2 md:pr-10 md:py-6">
              <div className="flex relative pb-12">
                <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-1 bg-gray-200 pointer-events-none" />
                </div>
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Effortless Appointment Addition:
                  </h2>
                  <p className="leading-relaxed">
                    Reception easily adds new appointments for patients.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-1 bg-gray-200 pointer-events-none" />
                </div>
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Flexible Appointment Updates:
                  </h2>
                  <p className="leading-relaxed">
                    Quick and easy updates to existing appointments for schedule
                    optimization.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-1 bg-gray-200 pointer-events-none" />
                </div>
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <circle cx={12} cy={5} r={3} />
                    <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3" />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Streamlined Appointment Removal:
                  </h2>
                  <p className="leading-relaxed">
                    Efficiently removes appointments, ensuring a fluid schedule.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-1 bg-gray-200 pointer-events-none" />
                </div>
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                    <circle cx={12} cy={7} r={4} />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Patient Profile Creation:
                  </h2>
                  <p className="leading-relaxed">
                    Reception creates new patient profiles seamlessly.
                  </p>
                </div>
              </div>
              <div className="flex relative">
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">
                    Simultaneous Appointment Scheduling:
                  </h2>
                  <p className="leading-relaxed">
                    Appointment creation for new patients during the
                    registration process.
                  </p>
                </div>
              </div>
            </div>
            <img
              className="hidden lg:flex xl:w-3/5 lg:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12"
              src={reception}
              alt="step"
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
