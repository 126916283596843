import React from "react";
import imageicon1 from "../../Images/image-icon-1.png";
import imageicon2 from "../../Images/image-icon-2.png";
import imageicon3 from "../../Images/image-icon-3.png";
import imageicon4 from "../../Images/image-icon-4.png";
import { NavLink } from "react-router-dom";
import reception from "../../Images/Reception.png";

export default function ReceptionApp() {
  return (
    <section
      id="about"
      className="about-us bg-slate-100 ptb-100 background-shape-img"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-5">
            <div className="about-content-right">
              <img src={reception} alt="about us" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-7">
            <div className="about-content-left section-heading">
              <span className="text-uppercase color-secondary sub-title">
                Reception App
              </span>
              <h2>
                ReceptionEase: <br />
                Simplifying Your Front Desk Operations
              </h2>
              <div className="single-feature mb-4 mt-5">
                <div className="icon-box-wrap d-flex align-items-center mb-2">
                  <div className="me-3 icon-box">
                    <img src={imageicon1} alt="icon" className="img-fluid" />
                  </div>
                  <p className="mb-0">
                    <strong>Patient Registration and Check-In: </strong>
                    <span>
                      Streamline the patient registration process, allowing for
                      efficient check-ins and reducing waiting times in the
                      hospital reception area.
                    </span>
                  </p>
                </div>
              </div>
              <div className="single-feature mb-4">
                <div className="icon-box-wrap mb-2">
                  <div className="me-3 icon-box">
                    <img src={imageicon2} alt="icon" className="img-fluid" />
                  </div>
                  <p className="mb-0">
                    <strong>Appointment Management: </strong>
                    <span>
                      Effectively manage and update patient appointments,
                      ensuring a well-organized schedule and minimizing
                      scheduling conflicts.
                    </span>
                  </p>
                </div>
                <p />
              </div>
              <div className="single-feature mb-4">
                <div className="icon-box-wrap mb-2">
                  <div className="me-3 icon-box">
                    <img src={imageicon3} alt="icon" className="img-fluid" />
                  </div>
                  <p className="mb-0">
                    <strong>Medical History Access: </strong>
                    <span>
                      ReceptionEase provides quick access to patient medical
                      history, enabling receptionists to assist healthcare
                      professionals promptly and enhancing overall patient care.
                    </span>
                  </p>
                </div>
                <p />
              </div>
              <div className="single-feature mb-4">
                <div className="icon-box-wrap mb-2">
                  <div className="me-3 icon-box">
                    <img src={imageicon4} alt="icon" className="img-fluid" />
                  </div>
                  <p className="mb-0">
                    <strong>Secure Information Handling: </strong>
                    <span>
                      Prioritize patient data security, ensuring confidentiality
                      and compliance with healthcare data protection standards,
                      maintaining trust and privacy.
                    </span>
                  </p>
                </div>
                <p />
              </div>
            </div>
            <NavLink
              to="/reception-app"
              className="btn solid-btn flex justify-left items-left my-3 max-w-36"
            >
              Know More
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}
