import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function AppLink() {
  const { id } = useParams();
  const navigate = useNavigate();

  const RedirectFucntion = async (AccountID) => {
    if (AccountID !== null) {
      axios
        .get("https://skdm.in/skddev/DrCrm/app-link.php?id=" + AccountID)
        .then((data) => {
          if (data.data.status === "success") {
            window.location = data.data.link;
          } else {
            navigate("/");
          }
        });
    }
  };

  useEffect(() => {
    if (id !== null) {
      RedirectFucntion(id);
    }
  }, [id]);

  return <div></div>;
}
