import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { UserOutlined } from "@ant-design/icons";
// import required modules
import { Autoplay } from "swiper/modules";
import { Avatar } from "antd";
import { NavLink } from "react-router-dom";

export default function Testimonial() {
  const doctorReviewsWithImages = [
    {
      doctorName: "Dr. Ananya Kapoor, MD",
      specialty: "General Medicine",
      review:
        "Stetho has seamlessly integrated into my practice. The Medicine Management feature allows me to prescribe and manage medications efficiently, enhancing patient care.",
      image: "link_to_image_placeholder1",
    },
    {
      doctorName: "Dr. Rohit Sharma, Cardiologist",
      specialty: "Cardiology",
      review:
        "As a cardiologist, the Growth and Vital feature empowers me to closely monitor patients' vital signs. Stetho's intuitive design aligns perfectly with my practice needs.",
      image: "link_to_image_placeholder2",
    },
    {
      doctorName: "Dr. Nisha Patel, Family Physician",
      specialty: "Family Medicine",
      review:
        "The Profile and Family Management feature is a game-changer. It simplifies updating patient profiles and managing family health records, promoting holistic healthcare.",
      image: "link_to_image_placeholder3",
    },
    {
      doctorName: "Dr. Sameer Reddy, Oncologist",
      specialty: "Oncology",
      review:
        "Stetho's Test and Procedure List is invaluable in oncology. It streamlines the coordination of necessary tests, facilitating smoother patient care. Highly recommended!",
      image: "link_to_image_placeholder4",
    },
    {
      doctorName: "Dr. Sanjay Gupta, General Surgeon",
      specialty: "General Surgery",
      review:
        "Managing hospital details on Stetho is effortless. I can customize and update information promptly, ensuring my patients have accurate and real-time data. A reliable tool for any doctor.",
      image: "link_to_image_placeholder5",
    },
  ];
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="section-heading mb-5 text-center">
          <span className="text-uppercase color-secondary sub-title text-center">
            Our Trusted Clients
          </span>
          <h2>What Clients Say About Us</h2>
          <p className="text-md text-center">
            Rapidiously morph transparent internal or "organic" sources whereas
            resource sucking e-business. Conveniently innovate compelling
            internal.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          <Swiper
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            className="mySwiper"
          >
            {doctorReviewsWithImages.map((value) => (
              <SwiperSlide>
                <div className="p-4 w-full">
                  <div className="h-full bg-gray-100 p-8 rounded">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="block w-5 h-5 text-gray-400 mb-4"
                      viewBox="0 0 975.036 975.036"
                    >
                      <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z" />
                    </svg>
                    <p className="leading-relaxed mb-6">{value.review}</p>
                    <a className="inline-flex items-center">
                      <Avatar
                        size={50}
                        icon={<UserOutlined />}
                        src={value.image}
                      />

                      <span className="flex-grow flex flex-col pl-4">
                        <span className="title-font font-medium text-gray-900">
                          {value.doctorName}
                        </span>
                        <span className="text-gray-500 text-sm">
                          {value.specialty}
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <NavLink
            to="/doctor-reviews"
            className="btn solid-btn flex justify-left items-left my-3 w-1/7 m-auto text-center"
          >
            Know More
          </NavLink>
        </div>
      </div>
    </section>
  );
}
