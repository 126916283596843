import React, { useState } from "react";
import { Button, Drawer } from "antd";
import { FaClock } from "react-icons/fa";

export default function SheduleDrawer({ hospitalList }) {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  console.log(hospitalList);
  return (
    <>
      <Button className="Clickbtn mt-3" onClick={showDrawer} icon={<FaClock />}>
        Hispital's Shedule
      </Button>
      <Drawer title="Hospital Shedules" onClose={onClose} open={open}>
        <div className="hosptalSheduleSection">
          {hospitalList?.map(
            (value, i) =>
              value?.shedules?.length > 0 && (
                <div className="hospitalCard" key={i}>
                  <h5 style={{ fontSize: "18px", margin: "10px 0" }}>
                    {value.first_name}
                  </h5>
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr style={{ background: "#eee" }}>
                        <th
                          style={{ padding: 10, border: "1px solid #ccc" }}
                          width="33%"
                        >
                          Day
                        </th>
                        <th
                          style={{ padding: 10, border: "1px solid #ccc" }}
                          width="33%"
                        >
                          Start
                        </th>
                        <th
                          style={{ padding: 10, border: "1px solid #ccc" }}
                          width="33%"
                        >
                          Closed
                        </th>
                      </tr>
                      {value?.shedules?.map((sheduleValue, i) => (
                        <tr key={i}>
                          <td style={{ padding: 10, border: "1px solid #ccc" }}>
                            {sheduleValue.hospital_weeek_name}
                          </td>
                          <td style={{ padding: 10, border: "1px solid #ccc" }}>
                            {sheduleValue.hospital_start_time}
                          </td>
                          <td style={{ padding: 10, border: "1px solid #ccc" }}>
                            {sheduleValue.hospital_end_time}
                          </td>
                        </tr>
                      ))}
                      {/* Add more appointment details here if needed */}{" "}
                    </tbody>
                  </table>
                </div>
              )
          )}
        </div>
      </Drawer>
    </>
  );
}
