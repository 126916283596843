import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, PhoneIcon } from "@heroicons/react/20/solid";
import logo from "../Images/Logo.png";
import { RiFeedbackLine } from "react-icons/ri";
import { LuMonitorSmartphone } from "react-icons/lu";
import { IoMdPhonePortrait } from "react-icons/io";
import { ImTablet } from "react-icons/im";
import { NavLink } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import "../CSS/NavBar.css";

const products = [
  {
    name: "Docter App",
    description: "Get a better understanding of your traffic",
    href: "/doctor-app",
    icon: LuMonitorSmartphone,
  },
  {
    name: "Reception App",
    description: "Speak directly to your customers",
    href: "/reception-app",
    icon: ImTablet,
  },
  {
    name: "Patient App",
    description: "Your customers’ data will be safe and secure",
    href: "/patient-app",
    icon: IoMdPhonePortrait,
  },
];
const callsToAction = [
  { name: "Feedback Us", href: "#", icon: RiFeedbackLine },
  { name: "Enquire Now", href: "#", icon: PhoneIcon },
];

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between px-6 py-3 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <NavLink to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-20 w-auto" src={logo} alt="stetho" />
          </NavLink>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(prev => !prev)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              Applications
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <NavLink
                          to={item.href}
                          className="block font-semibold text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </NavLink>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <NavLink
            to="/doctor-reviews"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            App Review's
          </NavLink>
          <NavLink
            to="/faqs"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            FAQ's
          </NavLink>
          {/* <NavLink
            to="#"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            About Us
          </NavLink> */}
          <NavLink
            to="/contact-us"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Contact Us
          </NavLink>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <NavLink
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://alpha.stetho.org.in/"
                    >
                      Doctor Login
                    </NavLink>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <NavLink
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://alpha.stetho.org.in/appointment/reception"
                    >
                      Reception Login
                    </NavLink>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <NavLink
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://play.google.com/store/apps/developer?id=Stetho"
                    >
                      Patient App
                    </NavLink>
                  ),
                },
              ],
            }}
          >
            <NavLink
              className="bg-black py-2 px-4 text-white"
              onClick={(e) => e.preventDefault()}
            >
              <strong>
                Log In&nbsp;
                <DownOutlined />
              </strong>
            </NavLink>
          </Dropdown>
        </Popover.Group>

        {/* ================ Responsive Screen ============= */}
        <div className={`smallScreenList flex lg:hidden ${mobileMenuOpen && "active"}`}>
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-base font-medium leading-6 text-gray-900">
              Applications
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <NavLink
                          to={item.href}
                          className="block font-semibold text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </NavLink>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <NavLink
            to="/doctor-reviews"
            className="text-base font-medium leading-6 text-gray-900 mt-2"
          >
            App Review's
          </NavLink>
          <NavLink
            to="/faqs"
            className="text-base font-medium leading-6 text-gray-900 mt-2"
          >
            FAQ's
          </NavLink>
          {/* <NavLink
            to="#"
            className="text-base font-medium leading-6 text-gray-900"
          >
            About Us
          </NavLink> */}
          <NavLink
            to="/contact-us"
            className="text-base font-medium leading-6 text-gray-900 mt-2"
          >
            Contact Us
          </NavLink>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <NavLink
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://alpha.stetho.org.in/"
                    >
                      Doctor Login
                    </NavLink>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <NavLink
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://alpha.stetho.org.in/appointment/reception"
                    >
                      Reception Login
                    </NavLink>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <NavLink
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://play.google.com/store/apps/developer?id=Stetho"
                    >
                      Patient App
                    </NavLink>
                  ),
                },
              ],
            }}
          >
            <NavLink
              className="bg-black py-2 px-4 text-white max-w-40 mt-2"
              onClick={(e) => e.preventDefault()}
            >
              <strong>
                Log In&nbsp;
                <DownOutlined />
              </strong>
            </NavLink>
          </Dropdown>
        </div>
      </nav>
    </header>
  );
}
