import React from "react";
// Section  Best features
import ImageSection4 from "../../Images/PatientApp.png";
import { FaUsers } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { IoIosListBox } from "react-icons/io";
import { NavLink } from "react-router-dom";

export default function PatientApp() {
  return (
    <div id="features" className="feature-section ptb-100 bg-slate-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="section-heading text-center mb-5">
              <span className="text-uppercase color-secondary sub-title">
                Patient App
              </span>
              <h2>Your Personal Health Companion, Anytime, Anywhere.</h2>
              <p className="w-full">
                Introducing HealthSync, the app that puts your health in your
                hands. Seamlessly book appointments, access your medical
                history, and receive digital prescriptions – all in one secure
                space. Stay connected with your healthcare journey effortlessly
                with HealthSync
              </p>
            </div>
          </div>
        </div>
        {/*feature new style start*/}
        <div className="row row-grid align-items-center g-4">
          <div className="col-lg-4">
            <div className="d-flex align-items-start mb-5">
              <div className="pe-4">
                <div className="icon icon-color-1 rounded-circle flex items-center justify-center">
                  <span className="ti-face-smile">
                    <FaPencilAlt />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5 className="font-bold text-gray-900">Appointment Booking</h5>
                <p className="mb-0 text-gray-500">
                  Choose convenient time slots, receive instant confirmations,
                  and set reminders.
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-5">
              <div className="pe-4">
                <div className="icon icon-color-2 rounded-circle flex items-center justify-center">
                  <span className="ti-face-smile">
                    <FaClipboardList />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5 className="font-bold text-gray-900">
                  Medical History Access
                </h5>
                <p className="mb-0 text-gray-500">
                  Access past diagnoses, treatments, and prescribed medications
                  for informed healthcare decisions.
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className="pe-4">
                <div className="icon icon-color-3 rounded-circle flex items-center justify-center">
                  <span className="ti-headphone-alt">
                    <IoIosListBox />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5 className="font-bold">Prescription Management</h5>
                <p className="mb-0 text-gray-500">
                  Manage and track prescribed medications for easy pharmacy
                  interactions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="position-relative" style={{ zIndex: 10 }}>
              <img
                alt="Image placeholder"
                src={ImageSection4}
                className="img-center img-fluid md:scale-125"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-start mb-5">
              <div className="pe-4">
                <div className="icon icon-color-4 rounded-circle flex items-center justify-center">
                  <span className="ti-layout-media-right">
                    <FaUsers />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5 className="font-bold">Family Account Integration</h5>
                <p className="mb-0 text-gray-500">
                  Connect multiple family members under one account for
                  streamlined management.
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-5">
              <div className="pe-4">
                <div className="icon icon-color-5 rounded-circle flex items-center justify-center">
                  <span className="ti-layout-cta-right">
                    <FaBell />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5 className="font-bold">Health Reminders</h5>
                <p className="mb-0 text-gray-500">
                  Set and receive reminders for medication schedules,
                  vaccinations, and routine health check-ups.
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className="pe-4">
                <div className="icon icon-color-6 rounded-circle flex items-center justify-center">
                  <span className="ti-palette">
                    <FaLock />
                  </span>
                </div>
              </div>
              <div className="icon-text">
                <h5 className="font-bold">Secure Communication</h5>
                <p className="mb-0 text-gray-500">
                  Easily share health-related information and receive timely
                  responses for a connected healthcare experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*feature new style end*/}
        <NavLink to="/patient-app">
          <button className="btn solid-btn flex justify-center items-center my-3 mx-auto">
            Know More
          </button>
        </NavLink>
      </div>
    </div>
  );
}
