import React from "react";
import ImageSection5 from "../../Images/app-hand-top.png";
import { AiFillAndroid } from "react-icons/ai";
import desktopImage from "../../Images/heroImage.png"
import { NavLink } from "react-router-dom";

export default function AboutApp() {
  return (
    <div>
      <section className="download-section pt-100 background-img">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content section-heading text-white pb-100">
                <h2 className="text-md text-white">
                  Stetho: Begin Streamlining Your Health Practice, Now at Faster
                  Speeds.
                </h2>
                <p className="lead">
                  Experience the swift transformation of your healthcare
                  practice with Stetho. Effortlessly manage appointments and
                  patient data for a more efficient and faster practice.
                </p>
                <div className="download-btn">
                  <NavLink to="https://play.google.com/store/apps/developer?id=Stetho" target="_blank" className="btn google-play-btn me-3 flex w-fit mt-4">
                    <span className="ti-android">
                      <AiFillAndroid />
                    </span>
                    Google Play
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end justify-center">
                <img src={desktopImage} alt="download" className="img-fluid scale-md-125" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Counter-section">
        <div className="container">
          <div class="Counter-Detials">
            <ul class="list-inline counter-wrap">
              <li class="list-inline-item">
                <div class="single-counter text-center">
                  <span className="font-bold text-4xl">70</span>
                  <h6 className="mt-2">Happy Client</h6>
                </div>
              </li>
              <li class="list-inline-item">
                <div class="single-counter text-center">
                  <span className="font-bold text-4xl">400</span>
                  <h6 className="mt-2">App Download</h6>
                </div>
              </li>
              <li class="list-inline-item">
                <div class="single-counter text-center">
                  <span className="font-bold text-4xl">200</span>
                  <h6 className="mt-2">Total Rates</h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
