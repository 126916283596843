import React from "react";
import "../CSS/doctorStyle.css";
import Footer from "../Component/Footer";
import { NavLink } from "react-router-dom";
import Hero from "../Component/DoctorComp/hero";
import Features from "../Component/DoctorComp/Features";
import AboutDoctorApp from "../Component/DoctorComp/AboutDoctorApp";

export default function DoctorApp() {
  return (
    <div>
      <Hero />

      <Features />
      <AboutDoctorApp />
      <Footer />
    </div>
  );
}
