import React from "react";
import Logo from "../Images/Logo.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font bg-slate-200">
      <div className="container md:px-5 py-10 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <img className="max-w-36" src={Logo} alt="footet logo" />
        </div>
        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-left">
          <div className="lg:w-1/4 md:w-1/2 px-4">
            <h2 className="title-font font-bold text-purple-600 tracking-widest text-sm mb-3">
              APPLICATION
            </h2>
            <nav className="list-none mb-10">
              <li>
                <NavLink
                  to="/doctor-app"
                  className="text-gray-600 hover:text-gray-900 hover:font-medium"
                >
                  Doctor App
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/patient-app"
                  className="text-gray-600 hover:text-gray-900 hover:font-medium"
                >
                  Patient App
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/reception-app"
                  className="text-gray-600 hover:text-gray-900 hover:font-medium"
                >
                  Reception App
                </NavLink>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 px-4">
            <h2 className="title-font font-bold text-purple-600 tracking-widest text-sm mb-3">
              QUICK LINKS
            </h2>
            <nav className="list-none mb-10">
              {/* <li>
                <NavLink
                  to="about-us"
                  className="text-gray-600 hover:text-gray-900 hover:font-medium"
                >
                  About Us
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/doctor-reviews"
                  className="text-gray-600 hover:text-gray-900 hover:font-medium"
                >
                  Reviews
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact-us"
                  className="text-gray-600 hover:text-gray-900 hover:font-medium"
                >
                  Contact Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/faqs"
                  className="text-gray-600 hover:text-gray-900 hover:font-medium"
                >
                  FAQ's
                </NavLink>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 px-4">
            <h2 className="title-font font-bold text-purple-600 tracking-widest text-sm mb-3">
              Legal
            </h2>
            <nav className="list-none mb-10">
              <li>
                <NavLink
                  to="/privacy-policy"
                  className="text-gray-600 hover:text-gray-900 hover:font-medium"
                >
                  Privacy & Policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="#"
                  className="text-gray-600 hover:text-gray-900 hover:font-medium"
                >
                  Terms & Condition
                </NavLink>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-gray-900">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-500 text-sm text-center sm:text-left">
            © 2024 Stetho — All rights reseved
          </p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            <NavLink className="text-gray-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
              </svg>
            </NavLink>
            <NavLink className="ml-3 text-gray-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
              </svg>
            </NavLink>
            <NavLink className="ml-3 text-gray-500">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width={20} height={20} x={2} y={2} rx={5} ry={5} />
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
              </svg>
            </NavLink>
            <NavLink className="ml-3 text-gray-500">
              <svg
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={0}
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                />
                <circle cx={4} cy={4} r={2} stroke="none" />
              </svg>
            </NavLink>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
