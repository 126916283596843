import React from "react";
import { NavLink } from "react-router-dom";

export default function Hero() {
  return (
    <div>
      <div className="container">
        <div class="bg-white pb-6 sm:pb-8 lg:pb-12">
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <section className="max-h-96 relative flex flex-1 shrink-0 items-center justify-center overflow-hidden rounded-lg bg-gray-100 py-16 shadow-lg md:py-20 xl:py-48">
              {/* image - start */}
              <img
                src="https://3407169.fs1.hubspotusercontent-na1.net/hubfs/3407169/benefits-of-medical-technology%20%281%29.jpg"
                loading="lazy"
                alt="Photo by Fakurian Design"
                className="absolute inset-0 h-full w-full object-cover object-center"
              />
              {/* image - end */}
              {/* overlay - start */}
              <div className="absolute inset-0 bg-indigo-300 mix-blend-multiply" />
              {/* overlay - end */}
              {/* text start */}
              <div className="relative flex flex-col items-center p-4 sm:max-w-xl">
                <p className="mb-4 text-center text-lg text-indigo-200 sm:text-xl md:mb-8">
                  Very proud to introduce
                </p>
                <h1 className="mb-8 text-center text-4xl font-bold text-white sm:text-5xl md:mb-12 md:text-6xl">
                  Stetho: Patient Management Application
                </h1>
                <div className="flex w-full flex-col gap-2.5 sm:flex-row sm:justify-center">
                  {/* <NavLink
                    to="#"
                    className="inline-block rounded-lg bg-red-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-red-600 focus-visible:ring active:bg-red-700 md:text-base"
                  >
                    Start now
                  </NavLink> */}
                  <NavLink
                    to="/contact-us"
                    className="inline-block rounded-lg bg-gray-200 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-300 focus-visible:ring active:text-gray-700 md:text-base"
                  >
                    Enquire Now
                  </NavLink>
                </div>
              </div>
              {/* text end */}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
