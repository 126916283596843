import React from "react";
import { RiTimeLine } from "react-icons/ri";
import { TbDeviceAnalytics } from "react-icons/tb";

export default function WhyChooseUs() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center section-heading">
          <span className="text-uppercase color-secondary sub-title">
            Why Choose Us
          </span>
          <h2 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
            Transforming Healthcare for a Better Tomorrow{" "}
          </h2>
          <p className="lg:w-2/3 w-full leading-relaxed text-gray-400">
            Explore the reasons why healthcare professionals are embracing
            Stetho, a cutting-edge platform designed to optimize practice
            management, enhance patient care, and revolutionize the way
            healthcare is delivered.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                </svg>
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Efficient Patient Management
              </h2>
              <p className="leading-relaxed text-base text-gray-400">
                Stetho streamlines patient data, appointments, and records,
                reducing administrative tasks and enhancing overall patient
                care.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 text-xl inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <RiTimeLine />
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Time-saving Appointment System
              </h2>
              <p className="leading-relaxed text-base text-gray-400">
                With a user-friendly scheduling feature, doctors can efficiently
                manage appointments, minimizing scheduling conflicts and
                optimizing clinic time.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
                </svg>
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Secure Data Handling
              </h2>
              <p className="leading-relaxed text-base text-gray-400">
                Stetho prioritizes data security, ensuring confidential patient
                information is handled with the utmost care and compliance with
                healthcare data protection standards.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7" />
                </svg>
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Integrated Billing Solutions
              </h2>
              <p className="leading-relaxed text-base text-gray-400">
                The platform simplifies billing processes, automating invoicing
                and payment tracking, providing a hassle-free financial
                management solution.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg>
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Real-time Collaboration
              </h2>
              <p className="leading-relaxed text-base text-gray-400">
                Stetho facilitates seamless communication among healthcare
                professionals, fostering collaboration and ensuring quick
                responses to patient needs.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 text-xl inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <TbDeviceAnalytics />
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Analytics for Informed Decisions
              </h2>
              <p className="leading-relaxed text-base text-gray-400">
                Doctors gain insights into practice performance, patient
                demographics, and trends, empowering informed decision-making
                for improved healthcare outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
