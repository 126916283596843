import React, { useEffect, useState } from "react";
import {
  Avatar,
  Divider,
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Badge,
  Breadcrumb,
  message,
  Skeleton,
  Spin,
} from "antd";
import { FaUser } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";
import logo from "../Images/Logo.png";
import axios from "axios";
import moment from "moment";
import calender from "../Images/calenderImage.png";
import SheduleDrawer from "../Component/SheduleDrawer";

export default function BookAppointment() {
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [doctorData, setDoctorData] = useState(null);
  const [hospitalList, setHospitalList] = useState([]);
  const [date, setDate] = useState(null);
  const [bookedAppontments, setBookedAppointments] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSlot, setLoadingSlot] = useState(false);
  const { token } = useParams();
  const [loadingAppt, setLoadingAppt] = useState(false);
  const [form] = Form.useForm();

  const handlleFetchDoctorData = async () => {
    try {
      setLoading(true);
      const data = await axios.get(
        "https://skdm.in/skddev/DrCrm/dr-profile-fetch.php?token=" + token
      );

      if (data) {
        setLoading(false);
        setDoctorData(data.data);
      }
    } catch (error) {
      message.error("Unable to get data please refresh page");
      console.error(error);
    }
  };

  useEffect(() => {
    handlleFetchDoctorData();
  }, []);

  // ============================= Hospital Fetch by according day ====================
  const hospital_list_fetch = (day) => {
    const hospitalIDs = doctorData?.hospitals
      .map((valueData) => {
        if (
          valueData.shedules.some(
            (valueFind) => valueFind.hospital_weeek_name === day
          )
        ) {
          return valueData;
        }
        return null;
      })
      .filter((valueFilter) => valueFilter !== null);

    setHospitalList(hospitalIDs);
  };

  //   ============== Handle Date Change  ====================
  const handleDateChange = (value) => {
    const day = value.format("dddd"); // 'dddd' represents the full day name (e.g., Monday, Tuesday, etc.)
    hospital_list_fetch(day);
    setSlots([]);
    setDate(value);
    setSelectedSlot(null);
  };

  // ================= Set time and duration on hospital change  =======================

  const handleChange = (value) => {
    const day = date.format("dddd");
    const selectedDate = date.format("YYYY-MM-DD");
    setSelectedHospital(value);

    const shedule = doctorData?.hospitals
      .filter((valueFilter) => valueFilter.list_of_account === value)
      .flatMap((valueFind) =>
        valueFind.shedules.filter(
          (findDay) => findDay.hospital_weeek_name === day
        )
      );

    const filterHospital = doctorData?.hospitals.filter(
      (valueFilter) => valueFilter.list_of_account === value
    );

    const startTime = shedule[0].hospital_start_time;
    const endTime = shedule[0].hospital_end_time;
    const duration = shedule[0].hospital_slot_duration;
    createTimeSlot(
      startTime,
      endTime,
      duration,
      filterHospital[0].list_of_account,
      selectedDate
    );
  };

  // ------------- Slot Generate Funtion --------------------

  const createTimeSlot = (
    fromTime,
    toTime,
    duration,
    hospitalId,
    selectedDate
  ) => {
    let startTime = moment(fromTime, "hh:mm A");
    let endTime = moment(toTime, "hh:mm A");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }
    let arr = [];
    while (startTime <= endTime) {
      arr.push(new moment(startTime).format("hh:mm A"));
      startTime.add(duration, "minutes");
    }
    setSlots(arr);
    fetchAppointment(hospitalId, selectedDate, arr);
  };

  // ================== Check time is passed or not ==================

  function isDateTimeInPast(targetDate, targetTime) {
    const targetDateForm = new Date(targetDate);
    // Get the current date and time
    const currentDate = new Date().toLocaleDateString("en-US");
    const currentTime = new Date().toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });

    // Combine the target date and time into a single string
    const targetDateTimeString = `${targetDateForm.toLocaleDateString(
      "en-US"
    )} ${targetTime}`;

    // Parse the target date and time, and current date and time
    const targetDateTimeParsed = new Date(targetDateTimeString);
    const currentDateTimeParsed = new Date(`${currentDate} ${currentTime}`);

    // Compare the target date and time with the current date and time
    return targetDateTimeParsed < currentDateTimeParsed;
  }

  // ========================= Fetch Appointment List ====================

  const fetchAppointment = (hospiatlId, date, timeSlots) => {
    try {
      setLoadingSlot(true);
      fetch("https://skdm.in/skddev/DrCrm/booking_appointment_view.php", {
        method: "POST",
        body: JSON.stringify({
          token: token,
          hospital_ID: hospiatlId,
          appointmentDate: date,
          action: "bookedList",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((resp) => resp.json())
        .then((apiData) => {
          if (Array.isArray(apiData)) {
            const excludedPerson = timeSlots.map(
              (time1) =>
                !apiData.some((time2) => time2.appointment_start_time === time1)
            );
            setBookedAppointments(excludedPerson);
          } else {
            setBookedAppointments([]);
          }
          setLoadingSlot(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  // ========================= Submit Appointment ==================

  const handleSubmitForm = async (value) => {
    try {
      setLoadingAppt(true);

      const dataRequest = {
        token: token,
        patient_Appointment_ID: "PID504",
        account_list_id_reff: selectedHospital,
        patient_id_reff: "",
        patient_email: value.email,
        appointment_start_time: selectedSlot,
        appointment_end_time: "",
        appointment_date: moment(new Date(date)).format("YYYY-MM-DD"),
        purpose_of_visit: value.purpose,
        patient_first_name: value.name.split(" ")[0],
        patient_last_name: value.name.split(" ")[1],
        patient_gender: value.gender,
        pateint_date_of_birth: moment(new Date(value.dob)).format("YYYY-MM-DD"),
        patient_phone_no: value.phone,
        visit_start_time: slots[0],
        appointment_type: "online",
        language: "English",
        appointment_status: "pending",
      };

      const headers = {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };
      const response = await axios.post(
        "https://skdm.in/skddev/DrCrm/booking_appointment_Register.php",
        dataRequest,
        headers
      );

      if (response) {
        form.resetFields();
        setSlots([]);
        setDate(null);
        setSelectedSlot(null);
        setLoadingAppt(false);
        message.success("Appointment booked successfully");
        
      }
    } catch (error) {
      message.error("Unable to book appointment please try again");
      setLoadingAppt(false);
      console.log(error);
    }
  };

  return (
    <Spin spinning={loadingAppt} >
      <div className="position-relative pb-5 mb-0">
        <div className="header">
          <div className="container  py-4">
            <div className="headerDetail d-flex align-items-center justify-content-between">
              <Breadcrumb
                items={[
                  {
                    href: "",
                    title: "Home",
                  },
                  {
                    href: "",
                    title: (
                      <>
                        <span>Book Apointment</span>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        Dr. {doctorData?.first_name} {doctorData?.last_name}
                      </>
                    ),
                  },
                ]}
              />
              <img style={{ maxWidth: "50px" }} src={logo} alt="logo" />
            </div>
            <Divider />
          </div>
        </div>

        <div className="container mb-5">
          <div className="row">
            <div className="col-md-10">
              {loading ? (
                <Skeleton
                  avatar
                  active
                  title
                  paragraph={{
                    rows: 3,
                  }}
                />
              ) : (
                <div className="doctorDetailSeprate d-flex ">
                  <Avatar
                    shape="square"
                    icon={<FaUser />}
                    size={100}
                    src={doctorData?.logo}
                    style={{
                      minWidth: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                  <div className="aboutDoctor mx-3">
                    <h4>
                      Dr. {doctorData?.first_name} {doctorData?.last_name}
                    </h4>
                    <strong>{doctorData?.degree}, {doctorData?.specialtion}, {doctorData?.additional_qaulification}</strong>
                    <p>{doctorData?.description}</p>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-2 d-flex justify-content-end">
             <SheduleDrawer hospitalList={doctorData?.hospitals} />
            </div>
            <div className="col-12">
              <Divider />
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="bookingDetails d-block d-md-none">
                <h3 style={{ fontSize: "1.4rem" }}>Booking Detail</h3>
                <DatePicker
                  placeholder="Appointment Date"
                  className="formInput w-100 mt-4"
                  onChange={(value) => handleDateChange(value)}
                  format="DD MMM YYYY"
                  value={date}
                />
                <Select
                  className="w-100 my-4"
                  placeholder="Select Hospital"
                  onChange={handleChange}
                  value={selectedHospital}
                >
                  {hospitalList?.map((value, i) => (
                    <Select.Option key={i} value={value.list_of_account}>
                      {value.first_name}
                    </Select.Option>
                  ))}
                </Select>

                <div className="slotDetails">
                  <Badge color="rgb(9, 57, 101)" text="Available" />
                  &nbsp;&nbsp;
                  <Badge color="rgb(1, 155, 1)" text="Selected" />
                  &nbsp;&nbsp;
                  <Badge color="rgba(255, 0, 0)" text="Booked" />
                  &nbsp;&nbsp;
                  <Badge color="rgb(0 0 0 / 25%)" text="Disabled" />
                  &nbsp;
                </div>
                <div className="slotList my-3">
                  {slots?.length > 0 ? (
                    !loadingSlot ? (
                      slots.map((time, i) => (
                        <button
                          onClick={() => setSelectedSlot(time)}
                          className={
                            bookedAppontments[i] === false
                              ? `slotCard booked`
                              : isDateTimeInPast(date, time)
                              ? `slotCard disabled`
                              : selectedSlot === time
                              ? `slotCard selected`
                              : `slotCard`
                          }
                          disabled={
                            isDateTimeInPast(date, time)
                              ? true
                              : bookedAppontments[i] === false
                              ? true
                              : false
                          }
                        >
                          {time}
                        </button>
                      ))
                    ) : (
                      <Skeleton.Button active size="default" shape="default" />
                    )
                  ) : (
                    <div className="selectSlot d-flex justify-content-center">
                      <img
                        src={calender}
                        style={{
                          width: "100%",
                          minWidth: "200px",
                          maxWidth: "200px",
                          margin: "auto",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <h3 style={{ fontSize: "1.4rem" }}>Pateint Detail</h3>
              <Form
                layout="vertical"
                className="mt-4"
                onFinish={handleSubmitForm}
                form={form}
              >
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      label="Patient Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        className="formInput"
                        placeholder="Full Name"
                        prefix={<FaUser />}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Phone Number"
                      name="phone"
                      rules={[{ required: true }]}
                    >
                      <Input
                        className="formInput"
                        placeholder="Phone"
                        prefix="+91"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Email ID"
                      name="email"
                      rules={[{ required: true }, { type: "email" }]}
                    >
                      <Input
                        className="formInput"
                        placeholder="example@dmain.com"
                        prefix={<MdEmail />}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Date of Birth"
                      name="dob"
                      rules={[{ required: true }, { type: date }]}
                    >
                      <DatePicker className="formInput w-100" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Gender"
                      name="gender"
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder="Select Gender"
                        options={[
                          {
                            label: "Male",
                            value: "male",
                          },
                          {
                            label: "Female",
                            value: "female",
                          },
                          {
                            label: "Other",
                            value: "other",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Purpose of visit"
                      rules={[{ required: true }]}
                      name="purpose"
                    >
                      <Select
                        placeholder="Select Gender"
                        options={[
                          {
                            label: "Consultation",
                            value: "Consultation",
                          },
                          {
                            label: "Follow Up",
                            value: "Follow Up",
                          },
                          {
                            label: "Show Reports",
                            value: "Show Reports",
                          },
                          {
                            label: "Certificate",
                            value: "Certificate",
                          },
                          {
                            label: "Counseling",
                            value: "Counseling",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-12">
                    <Divider />
                    <Form.Item>
                      <Button
                        className="submitBtn"
                        htmlType="submit"
                        style={{ minHeight: "40px" }}
                      >
                        Book Appointment
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
            <div className="col-lg-4 col-md-6 d-none d-md-block">
              <div className="bookingDetails">
                <h3 style={{ fontSize: "1.4rem" }}>Booking Detail</h3>
                <DatePicker
                  placeholder="Appointment Date"
                  className="formInput w-100 mt-4"
                  onChange={(value) => handleDateChange(value)}
                  format="DD MMM YYYY"
                  value={date}
                />
                <Select
                  className="w-100 my-4"
                  placeholder="Select Hospital"
                  onChange={handleChange}
                  value={selectedHospital}
                >
                  {hospitalList?.map((value, i) => (
                    <Select.Option key={i} value={value.list_of_account}>
                      {value.first_name}
                    </Select.Option>
                  ))}
                </Select>

                <div className="slotDetails">
                  <Badge color="rgb(9, 57, 101)" text="Available" />
                  &nbsp;&nbsp;
                  <Badge color="rgb(1, 155, 1)" text="Selected" />
                  &nbsp;&nbsp;
                  <Badge color="rgba(255, 0, 0)" text="Booked" />
                  &nbsp;&nbsp;
                  <Badge color="rgb(0 0 0 / 25%)" text="Disabled" />
                  &nbsp;
                </div>
                <div className="slotList my-3">
                  {slots?.length > 0 ? (
                    !loadingSlot ? (
                      slots.map((time, i) => (
                        <button
                          onClick={() => setSelectedSlot(time)}
                          className={
                            bookedAppontments[i] === false
                              ? `slotCard booked`
                              : isDateTimeInPast(date, time)
                              ? `slotCard disabled`
                              : selectedSlot === time
                              ? `slotCard selected`
                              : `slotCard`
                          }
                          disabled={
                            isDateTimeInPast(date, time)
                              ? true
                              : bookedAppontments[i] === false
                              ? true
                              : false
                          }
                        >
                          {time}
                        </button>
                      ))
                    ) : (
                      <>
                        {" "}
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                        <Skeleton.Button
                          className="m-1"
                          active
                          size="large"
                          shape="default"
                        />
                      </>
                    )
                  ) : (
                    <div className="selectSlot d-flex justify-content-center">
                      <img
                        src={calender}
                        style={{
                          width: "100%",
                          minWidth: "200px",
                          maxWidth: "200px",
                          margin: "auto",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer bg-dark p-2 text-center w-100 position-absolute bottom-0">
          <p style={{ color: "#fff" }}>
            Powered by{" "}
            <NavLink
              style={{ color: "#808080" }}
              target="_blank"
              to="https://stetho.org.in"
            >
              Stetho - Healthcare Management CRM
            </NavLink>
          </p>
        </div>
      </div>
    </Spin>
  );
}
